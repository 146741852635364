import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../StyleComponents/WhooskaSubscription.css';
import HeaderComponent from '../ReusableComponent/HeaderComponent';
import check from '../AssetsComponents/check.png';
import { FaExclamationCircle } from 'react-icons/fa';
import horse from '../AssetsComponents/horseRacing.png';
import logo from '../AssetsComponents/logoMain.png';
import image25 from '../AssetsComponents/youtube.png';
import image26 from '../AssetsComponents/twitter.png';
import Footer from '../ReusableComponent/FooterResuble'

function App() {
    const [checkedStates, setCheckedStates] = useState({
        melbourne: false,
        sydney: false,
        brisbane: false,
    });

    const [activeIndex, setActiveIndex] = useState(0); // Initialize activeIndex state
    const [loggedIn, setLoggedIn] = useState(false);
    const navigate = useNavigate();
    const scrollContainerRef = useRef(null);

    const handleCheckboxChange = (card) => {
        setCheckedStates((prevState) => ({
            ...prevState,
            [card]: !prevState[card],
        }));
    };

    const handleViewCart = () => {
        if (!loggedIn) {
            alert("Please log in to view the cart.");
            navigate('/SubscriptionsCartFirst');
            return;
        }

        if (checkedStates.melbourne || checkedStates.sydney || checkedStates.brisbane) {
            navigate('/SubscriptionsCartFirst');
        } else {
            alert("Please select at least one subscription before viewing the cart.");
        }
    };

    const handleScroll = () => {
        const scrollContainer = scrollContainerRef.current;
        const scrollPosition = scrollContainer.scrollLeft;
        const cardWidth = scrollContainer.offsetWidth;
        const newActiveIndex = Math.round(scrollPosition / cardWidth);
        setActiveIndex(newActiveIndex);
    };

    const scrollToCard = (index) => {
        const scrollContainer = scrollContainerRef.current;
        const cardWidth = scrollContainer.offsetWidth;
        scrollContainer.scrollTo({
            left: cardWidth * index,
            behavior: 'smooth',
        });
    };

    return (
        <>
            <HeaderComponent />
            <div className="image-containers" style={{ position: 'relative', width: '100%', height: '500px' }}>
                <img
                    src={horse}
                    alt="Banner"
                    style={{ height: '60%', width: '100%', objectFit: 'cover', filter: 'brightness(50%)' }}
                />
                <div className="text-overlays">
                    <p className='SubscriptionWhooska'>Subscription Plans</p>
                </div>
            </div>

            <div className="containerw">
                <div className="subscription-cards" ref={scrollContainerRef} onScroll={handleScroll}>
                    {/* Melbourne Tips Card */}
                    <div className="cardw">
                        <div className="card-headerw">
                            <h3>Melbourne Tips</h3>
                            <input
                                type="checkbox"
                                className="checkbox"
                                checked={checkedStates.melbourne}
                                onChange={() => handleCheckboxChange('melbourne')}
                            />
                        </div>
                        <div className="price-row">
                            <h4>$20 <span>/month</span></h4>
                            <span className="highlight">Saturday Tips</span>
                        </div>
                        <ul>
                            {[
                                "Tips for every race at your favourite metro tracks. The Full Whooska Set!!",
                                "Recommended Unit Bet/Strategy.",
                                "Tips received by 11am Saturday morning via email."
                            ].map((text, index) => (
                                <li key={index} style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
                                    <span>{text}</span>
                                </li>
                            ))}
                        </ul>
                        <button>Add to cart</button>
                    </div>

                    {/* Sydney Tips Card */}
                    <div className="cardw">
                        <div className="card-headerw">
                            <h3>Sydney Tips</h3>
                            <input
                                type="checkbox"
                                className="checkbox"
                                checked={checkedStates.sydney}
                                onChange={() => handleCheckboxChange('sydney')}
                            />
                        </div>
                        <div className="price-row">
                            <h4>$20 <span>/month</span></h4>
                            <span className="highlight">Saturday Tips</span>
                        </div>
                        <ul>
                            {[
                                "Tips for every race at your favourite metro tracks. The Full Whooska Set!!",
                                "Recommended Unit Bet/Strategy.",
                                "Tips received by 11am Saturday morning via email."
                            ].map((text, index) => (
                                <li key={index} style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
                                    <span>{text}</span>
                                </li>
                            ))}
                        </ul>
                        <button>Add to cart</button>
                    </div>

                    {/* Brisbane Tips Card */}
                    <div className="cardw">
                        <div className="card-headerw">
                            <h3>Brisbane Tips</h3>
                            <input
                                type="checkbox"
                                className="checkbox"
                                checked={checkedStates.brisbane}
                                onChange={() => handleCheckboxChange('brisbane')}
                            />
                        </div>
                        <div className="price-row">
                            <h4>$20 <span>/month</span></h4>
                            <span className="highlight">Saturday Tips</span>
                        </div>
                        <ul>
                            {[
                                "Tips for every race at your favourite metro tracks. The Full Whooska Set!!",
                                "Recommended Unit Bet/Strategy.",
                                "Tips received by 11am Saturday morning via email."
                            ].map((text, index) => (
                                <li key={index} style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
                                    <span>{text}</span>
                                </li>
                            ))}
                        </ul>
                        <button>Add to cart</button>
                    </div>

                    {/* Whooska Ultimate Package Card */}
                    <div className="cardw1 premium-cardw1">
                        <div className="card-headerw1">
                            <h3>Whooska Ultimate Package</h3>
                        </div>
                        <div className="price-row">
                            <h4>$50 <span>/month</span></h4>
                        </div>
                        <ul>
                            {[
                                <span style={{ color: "black", fontWeight: 'bold' }}>Purchase Tips</span> + " - Melbourne, Sydney, Brisbane.",
                                "Daily best bets (7 days a week) - Where Whooska's are available.",
                                "Quadie selections for Saturday tracks.",
                                "Access to Discord group chat to be part of our community."
                            ].map((text, index) => (
                                <li key={index} style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
                                    <span>{text}</span>
                                </li>
                            ))}
                        </ul>
                        <button>Add to cart</button>
                    </div>
                </div>
            </div>
            <div className='dots-container'>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
            </div>
            <div className="note-container">
                <div className="note-header">
                    <FaExclamationCircle className="note-icon" />
                    <span className="note-title">Please note</span>
                </div>
                <ul className="note-list">
                    <li>Tips will be emailed to your registered address and also shared on the discord group chat.</li>
                    <li>Monthly subscriptions will automatically be processed each month unless cancelled.</li>
                    <li>
                        If you cancel your monthly membership during the current month, you will not be refunded your monthly
                        subscription. However, your subscription will be cancelled from future transactions.
                    </li>
                </ul>
            </div>

            <div className="view-cart">
                <button onClick={handleViewCart}>View Cart</button>
            </div>

            <Footer/>

        </>
    );
}

export default App;
