import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useHistory
import '../StyleComponents/SubscriptionsCartFirst.css';
import HeaderComponent from '../ReusableComponent/HeaderComponent';

const Cart = () => {
    // Initial cart data
    const [cartItems, setCartItems] = useState([
        { name: 'Melbourne Tips', price: 10, quantity: 1, checked: true },
        { name: 'Sydney Tips', price: 10, quantity: 1, checked: true },
        { name: 'Brisbane Tips', price: 10, quantity: 1, checked: true }
    ]);
    const [voucherCode, setVoucherCode] = useState('');
    const [discount, setDiscount] = useState(0);
    const [total, setTotal] = useState(calculateTotal());
    const [originalTotal, setOriginalTotal] = useState(calculateTotal());
    const [isVoucherApplied, setIsVoucherApplied] = useState(false);
    const [promoMessage, setPromoMessage] = useState(''); // State for promo message

    const navigate = useNavigate(); // Get history object

    function calculateTotal() {
        return cartItems.reduce((acc, item) => item.checked ? acc + item.price * item.quantity : acc, 0);
    }

    const handleQuantityChange = (index, value) => {
        const updatedCart = [...cartItems];
        updatedCart[index].quantity = value;
        setCartItems(updatedCart);
        setTotal(calculateTotal());
        setOriginalTotal(calculateTotal());
    };

    const handleCheckboxChange = (index) => {
        const updatedCart = [...cartItems];
        updatedCart[index].checked = !updatedCart[index].checked;
        setCartItems(updatedCart);
        setTotal(calculateTotal());
        setOriginalTotal(calculateTotal());
    };

    const handleApplyVoucher = () => {
        // Check for valid promo codes
        if (voucherCode === 'DISCOUNT10') {
            setDiscount(10);
            setIsVoucherApplied(true);
            setPromoMessage('Promo Code Applied: DISCOUNT10'); // Set the promo message
        } else if (voucherCode === 'DISCOUNT20') {
            setDiscount(20);
            setIsVoucherApplied(true);
            setPromoMessage('Promo Code Applied: DISCOUNT20'); // Set the promo message
        } else {
            alert('Invalid voucher code');
            setDiscount(0);
            setIsVoucherApplied(false);
            setPromoMessage(''); // Reset promo message if voucher is invalid
        }
        // Calculate new total after applying discount
        const calculatedTotal = calculateTotal();
        setTotal(calculatedTotal - (calculatedTotal * (discount / 100)));
    };

    const discountAmount = (originalTotal * (discount / 100)).toFixed(2);

    const handleProceedToCheckout = () => {
        navigate('/Checkout', { // Use history.push to navigate to Checkout
            promoMessage, // Send promo message
            originalTotal, // Send original total
            total // Send discounted total
        });
    };

    return (
        <>
            <HeaderComponent />
            <h2 className='h22'>Cart</h2>
            <h3 className='Subs'>Subscriptions</h3>

            <div className="cart-container">
                <div className="subscriptions-box">
                    <table>
                        <thead>
                            <tr>
                                <th>Plan name</th>
                                <th>Quantity</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cartItems.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={item.checked}
                                            onChange={() => handleCheckboxChange(index)}
                                        />
                                        {item.name}
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={item.quantity}
                                            onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
                                        />
                                    </td>
                                    <td>${item.price}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="cart-container1">
                <div className="voucher-box">
                    <input
                        type="text"
                        placeholder="Voucher code"
                        value={voucherCode}
                        onChange={(e) => setVoucherCode(e.target.value)}
                    />
                    <button onClick={handleApplyVoucher}>Apply</button>
                </div>

                <div className="total-box" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '-40px' }}>
                    {isVoucherApplied ? (
                        <>
                            <p>Original Total:</p>
                            <p>${originalTotal.toFixed(2)}</p>
                        </>
                    ) : (
                        <>
                            <p>Total:</p>
                            <p>${total.toFixed(2)}</p>
                        </>
                    )}
                </div>

                {isVoucherApplied && (
                    <div className="total-box" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '-40px' }}>
                        <p>Discount:</p>
                        <p>-${discountAmount}</p>
                    </div>
                )}

                {isVoucherApplied && (
                    <div className="total-box" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '-40px' }}>
                        <p>Total After Discount:</p>
                        <p>${(total).toFixed(2)}</p>
                    </div>
                )}

                {/* {promoMessage && (
                    <div className="promo-message" style={{ color: 'green', fontWeight: 'bold' }}>
                        {promoMessage}
                    </div>
                )} */}

                <button className="checkout-button" onClick={handleProceedToCheckout}>Proceed to Checkout</button>
            </div>

        </>
    );
};

export default Cart;
