import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPageComponents from '../AuthenticationComponents/LandingPageComponents';
import WhyWhooskaComponents from '../AuthenticationComponents/WhyWhooskaComponents';
import WhooskaResultsComponents from '../AuthenticationComponents/WhooskaResultsComponents';
import WhooskaFreeTipsComponents from '../AuthenticationComponents/WhooskaFreeTipsComponents';
import WhooskaSubscription from '../AuthenticationComponents/WhooskaSubscription';
import LoginComponents from '../AuthenticationComponents/LoginComponents';
import RegisterComponent from '../AuthenticationComponents/RegisterComponent';
import SignupAuthentication from '../AuthenticationComponents/SignupAuthentication';
import ForgotPassword from '../AuthenticationComponents/ForgotPassword';
import ForgotPasswordVerifyCode from '../AuthenticationComponents/ForgotPasswordVerifyCode';
import SubscriptionsCartFirst from '../AuthenticationComponents/SubscriptionsCartFirst';
import Checkout from '../AuthenticationComponents/Checkout';
import ChangePassword from '../AuthenticationComponents/ChangePassword';
import YourTipsComponents from '../AllMainComponents/YourTipsComponents';
import PurchaseHistory from '../AllMainComponents/PurchaseHistory';
import ProfileForm from '../AllMainComponents/ProfileForm';
import ContactForm from '../AllMainComponents/ContactForm'

function AppNavigation() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPageComponents />} />
        <Route path="/WhyWhooskaComponents" element={<WhyWhooskaComponents />} />      
        <Route path="/WhooskaResultsComponents" element={<WhooskaResultsComponents />} />      
        <Route path="/WhooskaFreeTipsComponents" element={<WhooskaFreeTipsComponents />} />      
        <Route path="/WhooskaSubscription" element={<WhooskaSubscription />} />      
        <Route path="/LoginComponents" element={<LoginComponents />} />      
        <Route path="/RegisterComponent" element={<RegisterComponent />} />  
        <Route path="/SignupAuthentication" element={<SignupAuthentication />} />  
        <Route path="/SubscriptionsCartFirst" element={<SubscriptionsCartFirst />} />  
        <Route path="/Checkout" element={<Checkout />} />  
        <Route path="/ChangePassword" element={<ChangePassword />} />  
        <Route path="/YourTipsComponents" element={<YourTipsComponents />} />  
        <Route path="/PurchaseHistory" element={<PurchaseHistory />} />  
        <Route path="/ProfileForm" element={<ProfileForm />} /> 
        <Route path='/ContactForm' element={<ContactForm/>} />




        <Route path="/ForgotPassword" element={<ForgotPassword />} />  
        <Route path="/ForgotPasswordVerifyCode" element={<ForgotPasswordVerifyCode />} />  



      </Routes>
  </Router> 
   )
}

export default AppNavigation