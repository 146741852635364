
import axios from "axios"

// login API
const validateUser = async (userData) => {
	const response = await axios.post(`${process.env.REACT_APP_API_HOSTNAME}/users/validate-user`, userData, {
		headers: {
			'Content-Type': 'application/json'
		}
	})
	return response
}

// sign up API
const signUpUser = async (userData) => {
	const response = await axios.post(`${process.env.REACT_APP_API_HOSTNAME}/users/signup-user`, userData, {
		headers: {
			'Content-Type': 'application/json'
		}
	})
	return response
}

// send authentication code API
const sendAuthenticationCode = async (userData) => {
	const response = await axios.post(`${process.env.REACT_APP_API_HOSTNAME}/users/send-code`, userData, {
		headers: {
			'Content-Type': 'application/json'
		}
	})
	return response
}

// verify authentication code API
const verifyAuthenticationCode = async (userData) => {
	const response = await axios.post(`${process.env.REACT_APP_API_HOSTNAME}/users/verify-code`, userData, {
		headers: {
			'Content-Type': 'application/json'
		}
	})
	return response
}

export {
  validateUser,
	signUpUser,
	sendAuthenticationCode,
	verifyAuthenticationCode
}