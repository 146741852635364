import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import '../StyleComponents/ForgotPassword.css';
import logo from '../AssetsComponents/logoMain.png';
import image25 from '../AssetsComponents/youtube.png';
import image26 from '../AssetsComponents/twitter.png';

const ForgotPasswordForm = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState(""); // State for error message
    const navigate = useNavigate(); // Initialize navigate

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!email) {
            setError("Email is required"); // Set error message if email is not filled
            return;
        }

        // If email is filled, clear the error message
        setError("");

        // Simulate sending the code and then navigate to another page
        alert("Code has been sent to: " + email);
        navigate('/ForgotPasswordVerifyCode'); // Change this to your target route
    };

    return (
        <>
        <div className="header-container7">
                <header className="header7">
                    <div className="logo7">
                        <img src={logo} alt="Logo" />
                    </div>
                </header>
            </div>
            <div className="forgot-password-container">
                <div className="forgot-password-box">
                    <h1 className="forgot-password-title">Forgot Password</h1>
                    <p className="forgot-password-subtext">
                        Enter your registered email to reset your password
                    </p>
                    <form onSubmit={handleSubmit}>
                        <label className="emax">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="forgot-password-input12"
                            placeholder="user.whooska@gmail.com"
                        />
                        {error && <p className="error-message">{error}</p>} {/* Display error message */}
                        <button type="submit" className="forgot-password-button">
                            Send Code
                        </button>
                    </form>
                </div>
            </div>
            <div className="get-started-sections">
        <div className="footer-container1">
          <div className="left-side">
            <p style={{ color: '#FAD45C' }}>copyright @ whooska</p>
          </div>
          <p className="centered-paragraph">This page is for 18+ only. Gamble Responsibly. Visit <a href="#" style={{ color: '#FAD45C' }}>www.gamblinghelponline.org.vic.au</a> or call Gambling Help on <span style={{ color: '#FAD45C' }}>1800 858 858</span>. The chances are you are about to lose.</p>
          <div className="right-side4">
            <img src={image25} alt="Logo 1" className="log9" style={{ width: '40px', marginLeft: '10px' }} />
            <img src={image26} alt="Logo 2" className="log9" style={{ width: '40px', marginLeft: '10px' }} />
          </div>
        </div>
      </div>
        </>
    );
};

export default ForgotPasswordForm;
