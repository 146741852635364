import React, { useState } from 'react';
import '../StyleComponents/ContactForm.css';
import contact from '../AssetsComponents/contact.png';
import { faCaretRight, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import check from '../AssetsComponents/check_circle.png'
import HeaderComponent from '../ReusableComponent/HeaderComponentMain';
import image25 from '../AssetsComponents/youtube.png';
import image26 from '../AssetsComponents/twitter.png';
import logo from '../AssetsComponents/logoMain.png';
import Footer from '../ReusableComponent/FooterResuble'


const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const [isOpen, setIsOpen] = useState(false); // State to manage modal visibility

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [id]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsOpen(true); // Open modal on form submit
    };

    const closeModal = () => {
        setIsOpen(false);
        setFormData({ name: '', email: '', subject: '', message: '' }); // Reset form
    };

    return (
        <>
            <HeaderComponent />

            <div className="contact-container">
                <div className="form-header">
                    <h1 className="left-align">Contact Us</h1>
                </div>
                <div className="form-content">
                    <div className="form-image">
                        <img src={contact} alt="Contact Us" />
                    </div>
                    <div className="form-section44">
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="name" style={{ fontSize: 14 }}>Your name</label>
                            <input
                                type="text"
                                id="name"
                                className="form-input44"
                                placeholder="John Doe"
                                value={formData.name}
                                onChange={handleChange}
                            />

                            <label htmlFor="email" style={{ fontSize: 14 }}>Email</label>
                            <input
                                type="email"
                                id="email"
                                className="form-input44"
                                placeholder="johndoe@gmail.com"
                                value={formData.email}
                                onChange={handleChange}
                            />

                            <label htmlFor="subject" style={{ fontSize: 14 }}>Subject</label>
                            <input
                                type="text"
                                id="subject"
                                className="form-input44"
                                placeholder="Lorem Ipsum"
                                value={formData.subject}
                                onChange={handleChange}
                            />

                            <label htmlFor="message" style={{ fontSize: 14 }}>Your message</label>
                            <textarea
                                id="message"
                                className="form-input44"
                                placeholder="Type here"
                                value={formData.message}
                                onChange={handleChange}
                            ></textarea>

                            <button type="submit" className="submit-btn">Submit</button>
                        </form>
                    </div>
                </div>

                {/* Modal */}
                {isOpen && (
                    <div className="modal-overlay44">
                        <div className="modal-content44">
                            <div className="modal-header44">
                                <div className="modal-icon44">
                                    {/* You can use an icon library like FontAwesome or use an SVG here */}
                                    {/* <FontAwesomeIcon icon={faCircleCheck} className="modal-icon" /> */}
                                    <img src={check} alt="Logo 1" className="modal-icon" />

                                </div>
                                <h2 style={{ color: '#F7812A', fontSize: '24px', marginTop: '-20px' }}>Submitted</h2>
                            </div>
                            <div className="modal-body44">
                                <p>Your application is submitted, we will contact you within 24-72 hours</p>
                            </div>
                            <div className="modal-footer44">
                                <button onClick={closeModal} className="modal-close-btn44">Close</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Footer/>

        </>
    );
};

export default ContactForm;
