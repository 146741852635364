import React, { useState } from 'react';
import logo from '../AssetsComponents/logoMain.png';
import '../StyleComponents/HeaderComponent.css';
import { NavLink } from 'react-router-dom';

function HeaderComponent() {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(prev => !prev);
    };

    const getLinkStyle = (isActive) => ({
        color: isActive ? '#F7812A' : 'black',
        textDecoration: 'none',
    });

    return (
        <div className="header-containers">
            <header className="headers">
                <div className="logos">
                    <NavLink to="/LoginComponents" exact>
                        <img src={logo} alt="Logos11" />
                    </NavLink>
                </div>
                <div className="nav-containers">
                    <nav className="navs">
                    <div className="hamburger" onClick={toggleMenu}>
                        &#9776; {/* Hamburger icon */}
                    </div>
                        <ul className={isMenuOpen ? 'open' : ''}>
                            <li className='hamburgerItem'>
                                <NavLink to="/" exact style={({ isActive }) => getLinkStyle(isActive)}>Home</NavLink>
                            </li>
                            <li className='hamburgerItem'>
                                <NavLink to="/WhyWhooskaComponents" style={({ isActive }) => getLinkStyle(isActive)}>Why Whooska</NavLink>
                            </li>
                            <li className='hamburgerItem'>
                                <NavLink to="/WhooskaResultsComponents" style={({ isActive }) => getLinkStyle(isActive)}>Results</NavLink>
                            </li>
                            <li className='hamburgerItem'>
                                <NavLink to="/WhooskaFreeTipsComponents" style={({ isActive }) => getLinkStyle(isActive)}>Free Tips</NavLink>
                            </li>
                            <li className='hamburgerItem'>
                                <NavLink to="/WhooskaSubscription" style={({ isActive }) => getLinkStyle(isActive)}>Purchase Tips</NavLink>
                            </li>
                            <li className='hamburgerItem'>
                                <NavLink to="/ContactForm" style={({ isActive }) => getLinkStyle(isActive)}>Contact</NavLink>
                            </li>
                            <li className='hamburgerItem'>
                                <NavLink to="/LoginComponents" className='abs'>Log In</NavLink>
                            </li>
                            <li className='hamburgerItem'>

                                <NavLink to="/RegisterComponent" className='abs'>Sign Up</NavLink>
                            </li>
                            <li className="auth-links">
                                <NavLink to="/LoginComponents" className='abs1'>Log In</NavLink>
                                <NavLink to="/RegisterComponent" className='abs1'>Sign Up</NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
        </div>
    );
}

export default HeaderComponent;
