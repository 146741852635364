import React, { useState } from 'react';
import logo from '../AssetsComponents/logoMain.png'
import image18 from '../AssetsComponents/Vector.png'
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import horse from '../AssetsComponents/horseRacing.png'
import image25 from '../AssetsComponents/youtube.png'
import image26 from '../AssetsComponents/twitter.png'
import '../StyleComponents/WhooskaResultsComponents.css'
import { Link } from 'react-router-dom';
import HeaderComponent from '../ReusableComponent/HeaderComponent';
import Footer from '../ReusableComponent/FooterResuble'







function WhooskaResultsComponents() {
  const data = [
    {
      date: '10 Aug 2024',
      title: 'Saratoga Power',
      race: '06',
      location: 'Muswellbrook',
      status: 'Win',
      odd: '$6',
      stake: '$3',
      collected: '+$310',
      image: image18, // Ensure this is properly imported or linked
    },
    {
      date: '10 Aug 2024',
      title: 'Saratoga Power',
      race: '06',
      location: 'Muswellbrook',
      status: 'Win',
      odd: '$6',
      stake: '$3',
      collected: '+$310',
      image: image18, // Ensure this is properly imported or linked
    },
    {
      date: '10 Aug 2024',
      title: 'Saratoga Power',
      race: '06',
      location: 'Muswellbrook',
      status: 'Win',
      odd: '$6',
      stake: '$3',
      collected: '+$310',
      image: image18, // Ensure this is properly imported or linked
    },
    {
      date: '10 Aug 2024',
      title: 'Saratoga Power',
      race: '06',
      location: 'Muswellbrook',
      status: 'Win',
      odd: '$6',
      stake: '$3',
      collected: '+$310',
      image: image18, // Ensure this is properly imported or linked
    }, {
      date: '10 Aug 2024',
      title: 'Saratoga Power',
      race: '06',
      location: 'Muswellbrook',
      status: 'Win',
      odd: '$6',
      stake: '$3',
      collected: '+$310',
      image: image18, // Ensure this is properly imported or linked
    }, {
      date: '10 Aug 2024',
      title: 'Saratoga Power',
      race: '06',
      location: 'Muswellbrook',
      status: 'Win',
      odd: '$6',
      stake: '$3',
      collected: '+$310',
      image: image18, // Ensure this is properly imported or linked
    }, {
      date: '10 Aug 2024',
      title: 'Saratoga Power',
      race: '06',
      location: 'Muswellbrook',
      status: 'Win',
      odd: '$6',
      stake: '$3',
      collected: '+$310',
      image: image18, // Ensure this is properly imported or linked
    }, {
      date: '10 Aug 2024',
      title: 'Saratoga Power',
      race: '06',
      location: 'Muswellbrook',
      status: 'Win',
      odd: '$6',
      stake: '$3',
      collected: '+$310',
      image: image18, // Ensure this is properly imported or linked
    }, {
      date: '10 Aug 2024',
      title: 'Saratoga Power',
      race: '06',
      location: 'Muswellbrook',
      status: 'Win',
      odd: '$6',
      stake: '$3',
      collected: '+$310',
      image: image18, // Ensure this is properly imported or linked
    }, {
      date: '10 Aug 2024',
      title: 'Saratoga Power',
      race: '06',
      location: 'Muswellbrook',
      status: 'Win',
      odd: '$6',
      stake: '$3',
      collected: '+$310',
      image: image18, // Ensure this is properly imported or linked
    },
    // Add more data as needed
  ];

  const [selectedMonth, setSelectedMonth] = useState('August'); // Initial selected month

  const months = ['August', 'July', 'June'];

  return (
    <>
      <HeaderComponent />

      <div className="image-containers" style={{ position: 'relative', width: '100%', height: '500px' }}>
        <img
          src={horse}
          alt="Banner"
          style={{ height: '60%', width: '100%', objectFit: 'cover', filter: 'brightness(50%)' }}
        />
        <div
          className="text-overlays"
          style={{
            position: 'absolute',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            fontSize: '34px',
            fontWeight: 'bold',
            zIndex: '99999',
            width: '40%'
          }}
        >
          <p className='FreeWhoosk'>Results</p>
        </div>
      </div>
      <div className="results-section">
        {/* <h1 className='results-title'>Results</h1> */}
        <div className="results-grid">
          {data.map((item, index) => (
            <div key={index} className="result-card">
              <div className="result-card-header">
                <p>{item.date}</p>
              </div>
              <div className="result-card-content">
                <h3>
                  <img
                    src={item.image}
                    alt={item.title}
                    className="result-card-image"
                  />
                  {item.title}
                </h3>
              </div>
              <div className="result-card-race">
                <p>Race {item.race}</p>
              </div>
              <hr className="divider" />
              <div className="result-card-location">
                <p>{item.location}</p>
              </div>
              <div className="result-card-status">
                <p>
                  {item.status}
                  <IoCheckmarkCircleOutline
                    className="status-icon"
                  />
                </p>
              </div>
              <hr className="divider" />
              <div className="result-card-footer">
                <p>Odd</p>
                <p>Stake</p>
                <p>Collected</p>
              </div>
              <div className="result-card-footer-values">
                <span>{item.odd}</span>
                <span>{item.stake}</span>
                <span className="collected-amount">{item.collected}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Footer/>

    </>

  )
}

export default WhooskaResultsComponents